<template>
  <div>
     <div>
      <b-form
        ref="form"
        class="repeater-form"
        @submit.prevent="repeateAgain"
      >

        <!-- Row Loop -->
        <b-row
          v-for="(item, index) in costsData"
          :id="item.id"
          :key="item.id"
          ref="row"
        >

          <!-- Item Name -->
          <br/>
          <b-col md="6">
            <b-form-group
              label="Libelle prix"
              label-for="item-name"
            >
              <b-form-input
                id="item-name"
                type="text"
                placeholder="Vuexy Admin Template"
                v-model="item.name"
              />
            </b-form-group>
          </b-col>

          <!-- Cost -->
          <b-col md="2">
            <b-form-group
              label="Prix Horaire"
              label-for="cost"
            >
              <b-form-input
                id="cost"
                type="number"
                placeholder="32"
                 v-model="item.price"
              />
            </b-form-group>
          </b-col>

        
           <!-- Maj Button -->
          <b-col
            lg="2"
            md="3"
            class="mb-50"
          >
            <b-button v-if="!item.new == 1" 
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-primary"
              class="mt-0 mt-md-2"
              @click="updateItem(index , item)"
            >
              <feather-icon
                icon="Edit3Icon"
                class="mr-25"
              />
              <span>Mise à jour</span>
            </b-button>

            <b-button v-if="item.new == 1" 
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-primary"
              class="mt-0 mt-md-2"
              @click="addItem(index , item)"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>Ajouter ce prix</span>
            </b-button>
          </b-col>
         

          <!-- Remove Button -->
          <b-col
            lg="2"
            md="3"
            class="mb-50"
          >
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0 mt-md-2"
              @click="removeItem(index , item)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span>Delete</span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>

      </b-form>
    </div>
    
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="repeateAgain"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>Add New</span>
    </b-button>
  </div>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import { ref, onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'
import costStoreModule from './costsStoreModule'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
     ToastificationContent
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  methods: {
    repeateAgain() {
      console.log(this.costsData)
      this.costsData.push({
        new: 1
      })
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index , item) {
      if(index == 0 ){
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: "Vous ne pouvez pas supprimer ce prix",
            variant : 'danger'
          },
        })
        return true
      }

    

      this.$swal({
          title: 'Are you sure ?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          console.log(result)
          if (result.value) {
            store.dispatch('catalogue-price/deleteCost' , item.id )
            .then(response => { 
              console.log(response)
              if(response.status == 200){
                this.costsData.splice(index, 1)
               
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Notification',
                    icon: 'BellIcon',
                    text: '👋 le prix a été supprimé.',
                    variant : 'success'
                  },
                })
              }
            })
            .catch(error => {
            })
          }
        })

     
    },
    addItem(index , item){
      console.log(item)
     
      store.dispatch('catalogue-price/addCost' , item )
      .then(response => { 
        console.log(response)
        item.new = 0 ; 
        item.id = response.data.cost.id ; 
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: '👋 le prix a été crée.',
            variant : 'success'
          },
        })
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: error,
            variant : 'danger'
          },
        })
      })
    },
    updateItem(index , item) {
      store.dispatch('catalogue-price/updateCost' , item )
      .then(response => { 
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: '👋 le prix a été mise à jour.',
            variant : 'success'
          },
        })
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: error,
            variant : 'danger'
          },
        })
      })
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        //this.trSetHeight(104)
        console.log(this.$refs.form)
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },

  },
  setup() {
    const costsData = ref(null)
    let nextTodoId = 1 ; 
    const USER_APP_STORE_MODULE_NAME = 'catalogue-price'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, costStoreModule)
    
    store.dispatch('catalogue-price/fetchCosts')
      .then(response => { 
        costsData.value = response.data.costs
        nextTodoId = costsData.value.length + 1 
        console.log(this)
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 404) {
          costsData.value = undefined
      }
    })
    
    return {
      costsData,
      nextTodoId,
    }
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>