import axios from '@axios'

//var url = "http://127.0.0.1:8000/api";
var url = "https://api.pedra.fr/api";



export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCosts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(url+'/costs', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCost(ctx, { costId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(url+`/costs/${costId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCost(ctx, costData) {
      return new Promise((resolve, reject) => {
        axios
          .post(url+'/costs', { cost: costData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCost(ctx, costData) {
      return new Promise((resolve, reject) => {
        axios
          .put(url+`/costs/${costData.id}`, { cost: costData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },    
    deleteCost(ctx, costId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(url+'/costs/'+costId)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    
  },
}
